
.email-templates-swiper-container {
  box-sizing: border-box;
  position: relative;
  border: none;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .swiper-button-nav {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #000;
    background-color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &:after {
      display: none;
    }
    svg {
      width: 10px !important;
      height: 30px !important;
    }

    &.swiper-button-disabled {
      opacity: 1;
      border: 1px solid rgba(0, 0, 0, 0.35);
      & > * {
        opacity: 0.35;
      }
    }
  }
  .swiper-button-prev {
    left:0;
  }
  .swiper-button-next {
    right: 0;
    svg {
      margin-left: 2px;
    }
  }
  
}

.sketch-picker {
  margin-right: 20px;
}
.chrome-picker {
  margin-right: 20px;
}
.photoshop-picker {
  margin-right: 20px;

  & .flexbox-fix .flexbox-fix > div:nth-of-type(2) > div:nth-of-type(3) > div:nth-of-type(-n+4){
    display: none;
  }
}
