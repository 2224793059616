.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 25px;

  &__control {
    width: 28px;
    height: 25px;
    background-color: transparent;
    border: solid 1px #e9e9e9;
  }

  & .selected {
    font-weight: bold;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 15px;
    height: 15px;
    padding: 5px;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
  }

  &__prev,
  &__next {
    display: block;
    height: 25px;
  }
}
