.swiper {
  &-container {
    border: solid 1px grey;
    box-sizing: border-box;
    border-radius: 11px 11px 0 0;
  }

  &-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 310px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &-pagination-bullet {
    width: 10px;
    height: 10px;
    border: solid 1px white;
    background-color: white;
    opacity: 1;

    &-active {
      border: solid 1px white;
      background-color: #f95373;
    }
  }
}
